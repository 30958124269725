// Add your custom JS here.
jQuery(document).ready(function($) {
	$('.remove-p-tag p > img').unwrap();
});




// PAGE IMAGE SLIDER
jQuery(document).ready(function($) {
	$('.page-image-slider').each(function (index, sliderWrap) {
		var $slider = $(sliderWrap).find('.slider');
		$slider.slick({
			dots: true,
			adaptiveHeight: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
		});
	});
});